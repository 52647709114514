import styled from 'styled-components';

export const SearchInput = styled.input<{ focused: boolean }>`
  margin: 24px 60px;
  height: 60px;
  font-size: 36px;
  background-color: transparent;
  border: ${({ focused }) => (focused ? '4px' : '2px')} solid white;
  border-radius: 30px;
  padding: 0 30px;
  color: white;
  outline: none;
`;
